<template>
  <div class="inspection-plan-detail-wrapper">
    <div class="inspection-plan-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">备库单详情</span>
      </div>
    </div>
    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <template v-for="(item, index) in detailColumns">
              <el-col
                v-if="item.label"
                :key="item.key"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span style="color: #9597AE;">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item.key)||'-' }}</span>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
      <div class="detail-card">
        <!-- <div class="card-tit">列表信息</div> -->
        <el-tabs v-model="activeName" class="detail-tabs" type="border-card">
          <el-tab-pane label="货品清单" name="billOfGoodsList">
            <div class="card-bot card-bot2">
              <MTable
                :height="500"
                :columns="detailListColumns"
                :columns-setting="false"
                :show-page="false"
                :data="detail.billOfGoodsList||[]"
                :only-key="'uuid'"
              >
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="materialsCode" slot-scope="{ row }">
                  <NewPageOpen :path="{ name: 'materialsDetail', query: {id: row.materialsId}}" :text="row.materialsCode" />
                </div>
              </MTable>
            </div>
          </el-tab-pane>
          <el-tab-pane label="附件" name="attachment">
            <div class="card-bot card-bot2">
              <el-link v-for="item in attachment" :key="item.id" :href="item.url" target="_blank" style="padding-left:10px;">{{ item.name }}</el-link>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/production/reserveStockOrderManage'
import { detailColumns, detailListColumns, detailLogColumns, deliveryTypes } from './columns'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
export default {
  name: 'ReserveStockOrderDetail',
  components: { NewPageOpen },
  data() {
    return {
      detailColumns,
      detailListColumns,
      detailLogColumns,
      detail: {},
      activeName: 'billOfGoodsList',
      attachment: []
    }
  },
  created() {
    this.getDetail()
  },
  mounted() {
  },
  methods: {
    async getDetail() {
      const res = await api.viewDetail({ id: this.$route.query.id })
      !res.billOfGoodsList && (res.billOfGoodsList = [])
      this.detail = res
      this.attachment = JSON.parse(res.attachment) || []
      console.log('this.attachment', this.attachment)
    },

    getValue(key) {
      const result = this.detail[key]
      if (key === 'deliveryType') return deliveryTypes[this.detail[key]]
      return result
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-plan-detail-wrapper {
  background-color: #f0f2f5;
  height: 100%;

  .card-bot2{
    height: 520px;
  }

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .inspection-plan-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
}
</style>
