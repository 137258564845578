<template>
  <div class="orderAdd">
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form ref="ruleForm" label-position="right" :model="ruleForm" :rules="rules" label-width="98px" class="demo-form-inline">
          <el-row>
            <el-col :span="6">
              <el-form-item label="备库单编号" prop="code">
                <el-input v-model="ruleForm.code" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="交付日期"
                prop="deliveryTime"
              >
                <el-date-picker
                  v-model="ruleForm.deliveryTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期"
                  style="width:100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="负责人" prop="principalId">
                <el-select v-model="ruleForm.principalId" placeholder="请选择">
                  <el-option
                    v-for="item in principalIdOptions"
                    :key="item.id"
                    :label="item.userFullName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="备注" style="margin-bottom:23px;">
                <el-input
                  v-model="ruleForm.remark"
                  type="textarea"
                  autosize
                  placeholder="请输入内容"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <div class="bot-li">
                <div class="li-tit">附件：</div>
                <div class="li-des">
                  <el-upload
                    ref="upload"
                    class="upload-demo"
                    :file-list="fileList.slice(0, textFlg ? fileList.length : 3)"
                    v-bind="uploadProp"
                    :style="{ width: fileList.length > 3 ? '140px' : '150px' }"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <div v-show="fileList.length > 3" class="more" @click="textFlg = !textFlg">
                    {{ textFlg ? '收起' : `展开` }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">货品清单</div>
      <div class="card-bot">
        <el-button size="small" type="primary" class="e-btn" @click="selectAdd">添加物料</el-button>
        <MTable ref="mTable" :height="400" :show-page="false" :data="orderList" :columns="columnsOrder" :columns-setting="false">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <!-- <span slot="orderQuantityHeader">*</span> -->
          <div slot="code" slot-scope="{ row }">
            <NewPageOpen :path="{ name: 'materialsDetail', query: {id: row.id}}" :text="row.code" />
          </div>
          <div slot="orderQuantity" slot-scope="{ row }">
            <el-input-number v-model="row.orderQuantity" :min="0" controls-position="right" :step="1" @change="val=>onInput(val,row)" />
          </div>
          <div slot="remark" slot-scope="{ row }">
            <el-input v-model="row.remark" />
          </div>
          <div slot="action" slot-scope="{ row,$index }">
            <!--<el-button type="text" class="li-btn" @click="()=>orderList.splice($index + 1, 0, _.cloneDeep(row))">复制</el-button>-->
            <el-button type="text" class="li-btn" @click="()=>orderList.splice($index,1)">移除</el-button>
          </div>
        </MTable>
      </div>
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="returnList">取消</el-button>
        <el-button type="primary" @click="save">确定</el-button>
      </div>
    </div>
    <select-materials
      ref="selectMaterials"
      :select-materials="orderList"
      :visible.sync="showMaterials"
      :is-producible="true"
      :is-add="true"
      @submitForm="submitMaterials"
      @addMaterial="addMaterial"
    />
    <!-- 新增物料 -->
    <edit-materials ref="editMaterials" @onSuccess="onSuccess" />
  </div>
</template>
<script>
  import saveAs from 'file-saver'
  import api from '@/api/production/reserveStockOrderManage'
  import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
  import EditMaterials from '@/views/information/materials/components/edit-materials'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import { getToken } from '@/utils/auth'
  import { columnsOrder } from './columns'
  import dayjs from 'dayjs'

  const validatePass = function(rule, value, callback) {
    if (value?.length > 30) {
      return callback(new Error('最多30个字符'))
    }
    callback()
  }

  export default {
    name: 'ReserveStockOrderAdd',
    components: {
      EditMaterials,
      SelectMaterials,
      NewPageOpen
    },
    data() {
      return {
        columnsOrder,
        orderList: [],
        showMaterials: false,
        fileList: [],
        textFlg: false,
        uploadProp: {
          action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
          headers: { token: getToken() },
          showFileList: true,
          onSuccess: this.fileUploadChange,
          onRemove: this.fileRemove,
          onError: this.fileUploadChange,
          onPreview: this.handlePreview
        },
        deliveryTypeOptions: [{
          label: '统一交付',
          value: 0
        }, {
          label: '分批交付',
          value: 1
        }],
        customerIdOptions: [],
        principalIdOptions: [],
        ruleForm: {
          code: '',
          principalId: '',
          attachment: '',
          deliveryTime: dayjs(new Date()).format('YYYY-MM-DD 00:00:00'),
          remark: '',
          orderSource: 0
        },
        rules: {
          code: [
            { required: true, message: '请输入记录编号', trigger: 'blur' }
          ],
          deliveryTime: [
            { required: true, message: '请选择交付日期', trigger: 'blur' }
          ],
          principalId: [
            { required: true, message: '请选择负责人', trigger: 'blur' }
          ]
        }
      }
    },
    // watch: {
    //   'ruleForm.deliveryType'(val) {
    //     const column = {
    //       prop: 'deliveryTime',
    //       label: '交付日期',
    //       hiddenSearch: true,
    //       sortable: false,
    //       slotName: 'deliveryTime',
    //       minWidth: 242
    //     }
    //     const idx = this.columnsOrder.findIndex(item => item.prop === 'deliveryTime')
    //     if (val === 0) {
    //       idx && this.columnsOrder.splice(idx, 1)
    //     } else {
    //       idx === -1 && this.columnsOrder.splice(-2, 0, column)
    //     }
    //     this.visibleSel && (this.orderList = [])
    //   }
    // },
    beforeRouteLeave(to, from, next) {
      if (this.ruleForm.code) localStorage.setItem('reserveStockOrderAddType', 3)
      next()
    },
    beforeRouteEnter(to, from, next) {
      console.log(123)
      const status = localStorage.getItem('reserveStockOrderAddType') * 1
      if ([1, 2].includes(status)) {
        next(vm => vm.init())
      }
      next()
    },
    methods: {
      async init() {
        this.getPrincipalIdOptions()

        switch (this.$route.query.type) {
          case 'edit':
            await this.getDetail()
            break

          case 'add':
            this.resetData()
            this.ruleForm.principalId = JSON.parse(sessionStorage.getItem('userInfo')).id
            this.getCode()
            break

          default:
            break
        }
      },

      onInput(val, row, num = 2) {
        const valStr = val + ''
        const decimalLength = valStr.split('.')[1]?.length
        if (decimalLength > num) {
          row.orderQuantity = valStr.slice(0, valStr.length - (decimalLength - num)) * 1
        }
      },

      onDel(idx) {
        this.orderList.splice(idx, 1)
      },

      onCopy(idx, row) {
        this.orderList.splice(idx + 1, 0, row)
      },

      selectAdd() {
        this.showMaterials = true
      },

      onSuccess() {
        this.$refs.selectMaterials.updateTable()
      },

      submitMaterials(val) {
        if (!val) return
        // 获取对应库存量
        this.setMaterialsVariableStock(val)
        this.showMaterials = false
      },

      async setMaterialsVariableStock(val) {
        const ids = val.map(item => item.id)
        const res = await api.getMaterialsVariableStock({ ids: ids.join(',') })
        if (res) {
          const resMap = res.reduce((obj, item) => {
            obj[item.id] = item
            return obj
          }, {})
          const ids2 = this.orderList.map(item => item.id)
          val.forEach(item => {
            if (!ids2.includes(item.id)) {
              item.availableStock = resMap[item.id].stock
              !item.orderQuantity && (this.$set(item, 'orderQuantity', 0))
              this.orderList.push(item)
            }
          })
        }
      },

      addMaterial() {
        this.$refs.editMaterials.add()
      },

      // 获取详情
      async getDetail() {
        const res = await api.viewDetail({ id: this.$route.query.id })
        if (res) {
          const { code, principalId, remark, deliveryTime, billOfGoodsList, attachment } = res
          this.orderList = billOfGoodsList || []
          this.orderList = this.orderList.map(item => {
            const { id: itemId, materialsId: id, materialsCode: code, materialsName: name, materialSpecifications: specifications, materialUnitName: mainUnitName, availableStock, orderQuantity, remark } = item
            return { itemId, id, code, name, specifications, mainUnitName, availableStock, orderQuantity, remark }
          })
          this.fileList = attachment ? JSON.parse(attachment) : []
          this.ruleForm = {
            code,
            principalId,
            attachment,
            deliveryTime,
            remark
          }
        }
      },
      resetData() {
        this.ruleForm = {
          code: '',
          // deliveryType: 0,
          principalId: '',
          attachment: '',
          deliveryTime: dayjs(new Date()).format('YYYY-MM-DD 00:00:00'),
          remark: '',
          orderSource: 0
        }
        this.orderList = []
        this.fileList = []
      },

      // 获取记录编号
      async getCode() {
        const res = await api.getCode()
        if (res) {
          this.ruleForm.code = res
        }
      },

      // 获取负责人
      async getPrincipalIdOptions() {
        const res = await api.getResponsible()
        if (res) {
          this.principalIdOptions = res || []
        }
      },

      async save() {
        const flag1 = await this.$refs.ruleForm.validate()
        if (!this.orderList.length) return this.$message.warning('请添加物料')
        // let flag2 = 0
        // if (this.ruleForm.deliveryType === 1) {
        //   this.orderList.forEach(item => {
        //     this.$set(item, 'isWarning', !item.deliveryTime)
        //     if (!item.deliveryTime) flag2++
        //   })
        // }
        // if (flag2) return this.$message.warning('请选择交付日期')
        if (flag1) {
          const apis = {
            add: api.addReserveStockOrder,
            edit: api.updateReserveStockOrder
          }
          const type = this.$route.query.type
          const params = this.formateParm()
          const res = await apis[type](params)
          if (res) {
            this.$message.success('操作成功')
            this.returnList()
          }
        }
      },

      formateParm() {
        const { code, principalId, deliveryTime, remark } = this.ruleForm
        const billOfGoodsList = this.orderList.map(item => {
          const { itemId, id: materialsId, orderQuantity, remark } = item
          return { itemId, materialsId, orderQuantity, remark }
        })

        const params = { code, principalId, deliveryTime, remark, orderSource: 0, billOfGoodsList, attachment: JSON.stringify(this.fileList), id: this.$route.query.id }
        // if (this.ruleForm.deliveryType === 0) {
        //   billOfGoodsList.forEach(item => {
        //     delete item.deliveryTime
        //   })
        // } else {
        //   delete params.deliveryTime
        // }
        return params
      },

      returnList() {
        this.resetData()
        this.$router.go(-1)
      },

      handlePreview(file) {
        if (file.url.indexOf('.txt') > 0) {
          saveAs(file.url, '文件')
          return
        }
        window.open(file.url)
      },
      fileRemove(file) {
        const index = this.fileList.findIndex(item => item.id === file.id)
        this.fileList.splice(index, 1)
      },
      // 上传附件
      fileUploadChange(res, file) {
        if (res.errorCode === 0) {
          this.fileList.push({
            uid: file.uid,
            id: file.uid,
            name: file.name,
            url: res.result
          })
          return this.$message.success('上传成功')
        }
        if (res.errorCode !== 0) {
          return this.$message.error(res.result)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .el-upload-list__item.is-success.focusing .el-icon-close-tip {
    display: none !important;
  }
  ::v-deep {
    .el-tag {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .el-form-item {
      margin-bottom: 22px;
    }
    .el-date-editor .el-range-separator,
    .el-date-editor .el-range__icon {
      line-height: 26px;
    }
    .el-input-number {
      height: 32px;
      line-height: 32px;
      width: 100%;
    }
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input-number.is-controls-right .el-input-number__increase,
    .el-input-number.is-controls-right .el-input-number__decrease {
      line-height: 15px;
    }
    .el-tag .el-icon-close {
      position: absolute;
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 16px;
      vertical-align: middle;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
    }
  }
  .el-tags {
    padding: 0 20px 0 10px;
    position: relative;
  }
  .orderAdd {
    width: 100%;
    height: 100%;
    background-color: #f0f2f5;
    .input-new-tag {
      width: 130px;
    }
    .button-new-tag {
      height: 32px;
    }
    .detail-card {
      margin-bottom: 20px;
    }
    .lines {
      width: 100%;
      height: 20px;
      background: #f0f2f5;
    }
    ::v-deep .el-select {
      width: 100%;
    }
    .e-btn {
      margin-bottom: 10px;
    }
    .order-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      background: #fff;
      border-top: 1px solid #dde3eb;
      .footer-le {
        padding-left: 30px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393d60;
        span {
          margin-right: 45px;
          display: inline-block;
        }
      }
      .footer-ri {
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .bot-li {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    .li-tit {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 700;
      color: #606266;
      line-height: 32px;
      width: 95px;
      padding-right: 12px;
      text-align: right;
    }
    .li-des {
      position: relative;
      .more {
        // margin: 0 0 0 110px;
        cursor: pointer;
        width: 45px;
        height: 20px;
        background: #ebeef5;
        text-align: center;
        border-radius: 5px;
        margin: 10px auto;
        line-height: 20px;
        position: absolute;
        top: 32px;
        right: -60px;
      }
    }
  }

  .procedure-detail-header {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456b;
    line-height: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    img {
      width: 8px;
      height: 12px;
      display: block;
      margin-right: 10px;
    }
  }
</style>
