var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "Active" } }, [
            _vm._v("活跃备库单")
          ]),
          _c("el-radio-button", { attrs: { label: "History" } }, [
            _vm._v("历史备库单")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SearchForm", {
                ref: "searchForm",
                staticStyle: { padding: "0" },
                attrs: {
                  "form-list-extend": _vm.searchFormList,
                  "form-data": _vm.searchFormData
                },
                on: {
                  "update:formData": function(data) {
                    return (_vm.searchFormData = data)
                  },
                  search: _vm.reSearch
                }
              })
            ],
            1
          ),
          _c(
            "FunctionHeader",
            {
              ref: "functionHeader",
              staticClass: "func-header",
              staticStyle: { padding: "10px 0 10px 0" },
              attrs: {
                "search-title": "请输入备库单编号",
                tags: !_vm.selectIds.length ? _vm.functionHeader.tags : []
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectIds.length,
                      expression: "selectIds.length"
                    }
                  ],
                  attrs: { slot: "all" },
                  slot: "all"
                },
                [
                  _vm.permission("End") && _vm.params.radio === "Active"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.onHandle(_vm.selectIds, "finish")
                            }
                          }
                        },
                        [_vm._v("批量完结")]
                      )
                    : _vm._e(),
                  _vm.permission("Cancellation") &&
                  _vm.params.radio === "Active"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.onHandle(_vm.selectIds, "cancellation")
                            }
                          }
                        },
                        [_vm._v("批量作废")]
                      )
                    : _vm._e(),
                  _vm.permission("Restart") && _vm.params.radio === "History"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.onHandle(_vm.selectIds, "restart")
                            }
                          }
                        },
                        [_vm._v("批量重启")]
                      )
                    : _vm._e(),
                  _vm.permission("Del") && _vm.params.radio === "History"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.onHandle(_vm.selectIds, "del")
                            }
                          }
                        },
                        [_vm._v("批量删除")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "0 10px",
                        display: "inline-block",
                        "font-size": "12px",
                        color: "#393D60"
                      }
                    },
                    [
                      _vm._v(" 已选 "),
                      _c("span", { staticStyle: { color: "#607FFF" } }, [
                        _vm._v(_vm._s(_vm.getSelectionDataLength))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: { click: _vm.resetData }
                    },
                    [_vm._v("清空")]
                  )
                ],
                1
              )
            ]
          ),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columns,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            on: { "selection-change": _vm.selectionChanges },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "code",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c("NewPageOpen", {
                        attrs: {
                          path: {
                            name: "reserveStockOrderDetail",
                            query: { id: row.id }
                          },
                          text: row.code
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "orderSource",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(_vm.orderSource[row.orderSource]) + " ")
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.params.radio === "Active",
                            expression: "params.radio==='Active'"
                          }
                        ]
                      },
                      [
                        _vm.permission("End")
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: row.progress < 100
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle([row.id], "finish")
                                  }
                                }
                              },
                              [_vm._v("完结")]
                            )
                          : _vm._e(),
                        _vm.permission("Cancellation")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle(
                                      [row.id],
                                      "cancellation"
                                    )
                                  }
                                }
                              },
                              [_vm._v("作废")]
                            )
                          : _vm._e(),
                        _vm.permission("Edit")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.jump("edit", row.id)
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.params.radio === "History",
                            expression: "params.radio==='History'"
                          }
                        ]
                      },
                      [
                        _vm.permission("Restart")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle([row.id], "restart")
                                  }
                                }
                              },
                              [_vm._v("重启")]
                            )
                          : _vm._e(),
                        _vm.permission("Del")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle([row.id], "del")
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }