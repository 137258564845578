var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-plan-detail-wrapper" }, [
    _c("div", { staticClass: "inspection-plan-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v("备库单详情")]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "inspection-plan-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-row",
              { staticClass: "view-list", attrs: { gutter: 24 } },
              [
                _vm._l(_vm.detailColumns, function(item, index) {
                  return [
                    item.label
                      ? _c(
                          "el-col",
                          {
                            key: item.key,
                            style: { marginBottom: index !== 4 ? "20px" : 0 },
                            attrs: { span: 6 }
                          },
                          [
                            _c("span", { staticStyle: { color: "#9597AE" } }, [
                              _vm._v(_vm._s(item.label) + "：")
                            ]),
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(_vm.getValue(item.key) || "-"))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "detail-card" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "detail-tabs",
              attrs: { type: "border-card" },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "货品清单", name: "billOfGoodsList" } },
                [
                  _c(
                    "div",
                    { staticClass: "card-bot card-bot2" },
                    [
                      _c("MTable", {
                        attrs: {
                          height: 500,
                          columns: _vm.detailListColumns,
                          "columns-setting": false,
                          "show-page": false,
                          data: _vm.detail.billOfGoodsList || [],
                          "only-key": "uuid"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "materialsCode",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _c("NewPageOpen", {
                                    attrs: {
                                      path: {
                                        name: "materialsDetail",
                                        query: { id: row.materialsId }
                                      },
                                      text: row.materialsCode
                                    }
                                  })
                                ],
                                1
                              )
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "附件", name: "attachment" } },
                [
                  _c(
                    "div",
                    { staticClass: "card-bot card-bot2" },
                    _vm._l(_vm.attachment, function(item) {
                      return _c(
                        "el-link",
                        {
                          key: item.id,
                          staticStyle: { "padding-left": "10px" },
                          attrs: { href: item.url, target: "_blank" }
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }