<template>
  <div v-if="hadLogin" class="memberRouteSetting_container" :style="{height: chromeHeight + 'px'}">
    <div class="container_left">
      <h2>租户信息</h2>
      <template>
        <div v-for="(item,index) in userList" :class="{
          userItem:true,active: index === curtenIndex
        }"
             @click="viewCheckItem(index)">
          {{ item.memberName }} - {{ item.memberCode }}
        </div>
      </template>
    </div>
    <div class="container_right">
      <div class="model-wrap-right common-tab">
        <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
          <el-radio-button label="Role">功能权限</el-radio-button>
          <el-radio-button label="User">关联用户</el-radio-button>
        </el-radio-group>
      </div>
      <div v-if="params.radio === 'Role'" style="margin: 10px 20px;">
        <div class="all-btns">
          <label class="label">网页端功能权限</label>
          <el-button :loading="loading" size="small" type="primary" class="role-btn" @click="saveRole">保存</el-button>
        </div>
        <el-tree
          v-if="openPanel"
          ref="tree"
          show-checkbox
          node-key="name"
          :data="webRoutes"
          :props="defaultProps"
          :default-expanded-keys="['all']"
          :default-checked-keys="authWeb"
          :render-content="renderContent"
        />
        <div class="el-form-item">
          <label class="el-form-item__label">移动端功能权限</label>
        </div>
        <el-tree
          v-if="openPanelApp"
          ref="treeApp"
          show-checkbox
          node-key="name"
          :data="appRoutes"
          :props="defaultProps"
          :default-expanded-keys="['全部']"
          :default-checked-keys="authApp"
          :render-content="renderContentApp"
        />
      </div>
      <div v-if="params.radio === 'User'" style="margin: 10px 20px;">
        <el-table
          :data="showUsers"
          height="700"
          border
          style="width: 100%">
          <el-table-column
            prop="jobNumber"
            label="工号">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名">
          </el-table-column>
          <el-table-column
            prop="userName"
            label="用户名">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号码">
          </el-table-column>
          <el-table-column
            prop="email"
            label="邮箱">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="login-right" style="width: 100vw;height: 100vh;background: rgba(3, 13, 32, 0.6);">
      <div class="center" style="width: 30%;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -80%);">
        <div class="blank-placeholder" />
        <div class="form-wrapper">
          <div class="cac-logo" style="display: flex;justify-content: center;align-items: center;margin-bottom: 20px">
            <img src="https://oss.mubyte.cn/static/logo-new%402x.png" style="display: block;width: 200px">
          </div>
          <div class="login-tab">
            <div class="form" style="border-top-left-radius: 8px;">
              <el-form ref="loginForm" :model="loginForm" class="login-form"
                       label-position="left">
                <div>
                  <el-form-item prop="userName">
                    <el-input ref="userName" v-model="loginForm.userName" class="inline-input"
                                       placeholder="请输入用户名" style="width: 100%"
                                      tabindex="2" />
                  </el-form-item>
                  <el-form-item prop="password" style="padding-bottom: 0">
                    <el-input  ref="password" v-model="loginForm.password" type="password"
                              placeholder="请输入密码" name="password" tabindex="3" @keyup.enter.native="handleLogin" />
                  </el-form-item>
                </div>
                <el-button :loading="loading" type="primary" style="width:100%;color: #fff"
                           @click.native.prevent="handleLogin">立即登录
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tenant'
import { constantRoutes } from '@/router'
import { appMenu } from '@/views/setting/role/columns'
import baseSettingApi from '@/api/sets/base-setting'
import DownCode from '@/views/login/downCode.vue'
import {setRouteUserNameMd5,setRoutePasswordMd5} from '@/settings'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'MemberRouteSet',
  components: { DownCode },
  data() {
    return {
      loginForm:{
        userName: '',
        password: ''
      },
      hadLogin: false,
      userList: [],
      curtenIndex: 0,
      webRoutes: [],
      appRoutes: [],
      authWeb: [],
      authApp: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      params: {
        radio: 'Role'
      },
      openPanel: false,
      openPanelApp: false,
      judgeWeb:[],
      judgeApp:[],
      showUsers:[],
      loading: false,
      selectedUserMsg:{}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192 - 35
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    chromeHeight() {
      return document.documentElement.clientHeight
    }
  },
  async created() {
    const res = await api.getTenantListPage({
      page: 1,
      limit: 100
    })
    if (res) {
      // consoleSelf.info('获取到所有的租户信息', res.content)
      this.userList = res.content
      this.viewCheckItem(0)
    }
  },
  beforeMount() {
    this.webRoutes = [
      {
        name: 'all',
        meta: { title: '全部' },
        children: constantRoutes.filter((item) => !item.hidden)
      }
    ]
    this.handleWeb(this.webRoutes[0])
    // consoleSelf.info('web端所有路由信息(原始数据)', this.webRoutes)
    // consoleSelf.info('web端所有路由信息(判断数据)', this.judgeWeb)
    this.appRoutes = [
      {
        name: '全部',
        children: appMenu
      }
    ]
    this.handleApp(this.appRoutes[0])
    // consoleSelf.info('app端所有路由信息(原始数据)', this.appRoutes)
    // consoleSelf.info('app端所有路由信息(判断数据)', this.judgeApp)
  },
  methods: {
    handleLogin(){
      console.log(this.loginForm)
      if(!this.loginForm.userName || !this.loginForm.password){
        this.$message.error('请输入账号和密码')
        return false;
      }
      const userName = Encrypt(this.loginForm.userName)
      const userPwd = Encrypt(this.loginForm.password)
      if(userName === setRouteUserNameMd5 && userPwd === setRoutePasswordMd5){
        this.$message.success('登录成功')
        this.hadLogin = true
      }else {
        this.$message.error('账号或密码错误')
        return false;
      }
    },
    handleWeb(obj){
      if(obj.children && obj.children.length > 0){
        obj.children.forEach(item => {
          this.handleWeb(item)
        })
      }else {
        this.judgeWeb.push(obj.name)
      }
    },
    handleApp(obj){
      if(obj.children && obj.children.length > 0){
        obj.children.forEach(item => {
          this.handleApp(item)
        })
      }else {
        this.judgeApp.push(obj.name)
      }
    },
    renderContent(h, { data }) {
      return h('span', data.meta ? data.meta.title ? data.meta.title : data.meta.roleTitle : data.title ? data.title : data.roleTitle)
    },
    renderContentApp(h, { data }) {
      return h('span', data.name)
    },
    async viewCheckItem(index) {
      this.curtenIndex = index
      const users = await this.getUserList(this.userList[index].memberCode)
      this.showUsers = users
      const userRoute = await this.getTenatDetail(this.userList[index].id)
      this.selectedUserMsg = userRoute
      // consoleSelf.info('获取到当前租户的权限信息', userRoute)
      const originMsg = JSON.parse(userRoute.value) || ''
      if (originMsg) {
        const { web, app } = originMsg
        const finalWeb = web.filter(item => this.judgeWeb.includes(item))
        const finalApp = app.filter(item => this.judgeApp.includes(item))
        this.authWeb = finalWeb
        this.authApp = finalApp
        this.openPanel = false
        this.openPanelApp = false
        this.$nextTick(() => {
          this.openPanel = true
          this.openPanelApp = true
        })
      }
    },
    async getTenatDetail(id) {
      const res = await api.getTenantDetail({
        id
      })
      if (res) {
        return res.basicConfig
      } else {
        return null
      }
    },
    async getUserList(memberCode) {
      const res = await api.getTenantUserListPage({
        page: 1,
        limit: 100,
        memberCode
      })
      if (res) {
        return res.records
      } else {
        return []
      }
    },
    radioChange(type) {
      this.params.radio = type
    },
    async saveRole() {
      this.loading = true
      const checked = this.$refs.tree.getCheckedNodes().map((item) => {
        return item.name
      })
      const half = this.$refs.tree.getHalfCheckedNodes().map((item) => {
        return item.name
      })
      const finalRouteWeb = [...checked, ...half]
      // consoleSelf.info('最终web端权限信息', finalRouteWeb)

      const checkedApp = this.$refs.treeApp.getCheckedNodes().map((item) => {
        return item.name
      })
      const halfApp = this.$refs.treeApp.getHalfCheckedNodes().map((item) => {
        return item.name
      })
      const finalRouteApp = [...checkedApp, ...halfApp]
      // consoleSelf.info('最终app端权限信息', finalRouteApp)

      const routeInfo = {
        web: finalRouteWeb,
        app: finalRouteApp
      }

      const res = await baseSettingApi.modifySingleSetting({
        id: this.selectedUserMsg.id,
        code: this.selectedUserMsg.code,
        value: JSON.stringify(routeInfo),
        memberCode: this.selectedUserMsg.memberCode
      })
      if (res) {
        this.$message.success('保存成功')
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.memberRouteSetting_container {
  width: 100%;
  box-sizing: border-box;
  display: flex;

  .container_left {
    width: 30%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 10px;

    .userItem {
      height: 45px;
      padding-left: 15px;
      font-size: 15px;
      line-height: 45px;
      cursor: pointer;

      &.active {
        color: #FFFFFF;
        background: #717bdf;
        font-weight: bold;
      }
    }
  }

  .container_right {
    width: 70%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;

    .all-btns {
      display: flex;
      justify-content: space-between;

      .label {
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      .role-btn {
        height: 32px;
        margin-top: 4px;
      }
    }
  }
}
</style>

